<template>
  <div class="two-rows-wrapper">
    <main>
      <div class="warning" v-if="showCard">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.4443 14.5843L12.1986 14.7443C12.1501 14.7758 12.1084 14.8164 12.0759 14.8638C12.0433 14.9111 12.0206 14.9644 12.0089 15.0204C11.9973 15.0764 11.997 15.1341 12.0081 15.1902C12.0191 15.2464 12.0413 15.2998 12.0734 15.3475L12.1027 15.3923C12.197 15.5331 12.3906 15.5523 12.5207 15.5859C12.6311 15.5917 12.7403 15.6105 12.8461 15.6419C13.0342 15.7057 13.1944 15.8311 13.2999 15.9971C13.4626 16.2722 13.3568 16.6114 13.3178 16.7714C12.9697 18.1762 12.6134 19.5793 12.2718 20.9873C11.9872 22.16 12.6622 23 13.8985 23C15.2698 23 16.5012 22.5552 17.6268 21.8032C17.7049 21.7501 17.774 21.6853 17.8318 21.6113C18.053 21.3249 17.9684 21.1057 17.6122 21.0257C17.5081 21.0017 17.4007 20.9841 17.2982 20.9569C16.6199 20.7761 16.4442 20.4769 16.6102 19.8113C16.9631 18.3954 17.3243 16.981 17.6691 15.5635C17.9001 14.6243 17.4593 13.8531 16.5776 13.6435C16.3374 13.5906 16.0918 13.5648 15.8456 13.5667C15.5412 13.579 15.238 13.6111 14.9379 13.6627C14.7444 13.6979 14.5687 13.7379 14.406 13.7811C14.0932 13.8641 13.7857 13.9656 13.4853 14.0851C13.3031 14.1571 13.0722 14.2547 12.8103 14.3811L12.4443 14.5843ZM16.6931 7.00059C16.3879 6.99371 16.0844 7.04726 15.8007 7.15805C15.5169 7.26884 15.2587 7.43462 15.0414 7.64551C14.8241 7.85641 14.6522 8.10813 14.5357 8.38571C14.4193 8.66328 14.3608 8.96105 14.3637 9.2613C14.3581 9.86598 14.597 10.4481 15.0277 10.8795C15.4584 11.311 16.0458 11.5565 16.6606 11.562C17.2754 11.5675 17.8672 11.3326 18.3058 10.909C18.7445 10.4853 18.9941 9.90757 18.9997 9.3029C19.0045 9.0021 18.9483 8.70337 18.8345 8.42413C18.7207 8.14489 18.5515 7.89072 18.3368 7.67644C18.1221 7.46215 17.8662 7.29204 17.584 7.176C17.3018 7.05997 16.999 7.00034 16.6931 7.00059Z"
            fill="#FF9900"/>
        </svg>
        <p>Кандидатам в общественный совет и организациям, их выдвигающим, рекомендуем ознакомиться!</p>
        <div class="close" @click="showCard=false"></div>
      </div>
      <documet-candidate :documentsList="[{ name: getData.f_title, url: getData.f_url, size: formatBytes(getData.f_size), type: getData.f_type }]"/>
      <article v-html="textOne" class="column-wrapper ckEditor-html"></article>

      <article v-html="textTwo" class="column-wrapper ckEditor-html"></article>

      <documet-candidate :documentsList="[ { name: getData.s_title, url: getData.s_url, size: formatBytes(getData.s_size), type: getData.s_type } ]"/>

      <article v-html="textThree" class="column-wrapper ckEditor-html"></article>

    </main>
    <aside class="two-rows-navigation">
      <h2>Навигация по странице</h2>
      <nav>
        <a class="ckEditor-html" v-for="(nav, i) in navigation" :key="`nav_${i}`" :href="`#nav${i}`" v-html="nav"></a>
      </nav>
    </aside>
  </div>
</template>

<script>
import DocumetCandidate from '@/components/activitiesSurveys/DocumetCandidate'
import formatSizeFile from '@/mixins/formatSizeFile'
export default {
  name: 'CommunityCandidateConvocation',
  mixins: [formatSizeFile],
  components: {
    DocumetCandidate
  },
  data () {
    return {
      showCard: true,
      navigation: [],
      textOne: '',
      textTwo: '',
      textThree: ''
    }
  },
  mounted () {
    document.title = 'Кандидату в общественный совет'
    this.$store.dispatch('setCandidatePublicCouncil')
  },
  computed: {
    getData () {
      return this.$store.getters.getCandidatePublicCouncil
    }
  },
  watch: {
    '$store.getters.getCandidatePublicCouncil' () {
      const getData = this.$store.getters.getCandidatePublicCouncil

      function filterTag (arr, navigation) {
        arr.forEach((element, i) => {
          if (element.indexOf('</h2>') !== -1) {
            var text = element.slice(0, element.indexOf('</h2>'))
            if (text.indexOf('Дополнительные (специфические)') !== -1) {
              text = 'Дополнительные требования'
            }
            navigation.push(text)
            arr[i] = `<h2 id="nav${navigation.length - 1}">` + element
          }
        })
        return arr
      }
      var navigation = []

      this.textOne = filterTag(getData.f_text.split('<h2>'), navigation).join('')
      this.textTwo = filterTag(getData.s_text.split('<h2>'), navigation).join('')
      this.textThree = filterTag(getData.t_text.split('<h2>'), navigation).join('')
      this.navigation = navigation
    }
  }

}
</script>
<style lang="scss" scoped>
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
  h1 {
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1;
    color: #1F3245;
  }

  h2 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1;
    color: #1F3245;
  }
  .two-rows-wrapper{
    width: auto;
    .column-wrapper{
      display: block;
    }
     ::v-deep .column-wrapper h2 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.33;
      color: #1F3245;
    }

        a {
          color: #246CB7;
        }

        ::v-deep p, ::v-deep li {
          font-size: .875rem;
          line-height: 1.5;
          color: rgba(23, 23, 23, 0.8);
          margin: 1rem 0;
        }

        ::v-deep .ag {
          font-size: 1.125rem;
          line-height: 1.33;
          color: #1F3245;
          font-weight: 600;
        }

        ::v-deep span {
          color: #EB494E;
        }

        ::v-deep ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
          li {
            margin-left: 1rem;
          }
          > li::before {
            color: #246CB7;
            content: "\2022";
            font-size: 1rem;
            margin: 0 .6rem 0 -1rem;
          }
        }

        ::v-deep ol {
          counter-reset: li;
          font-weight: 600;
          list-style-type: none;
          margin: 0;
          padding: 0;

          > li::before {
            content: counter(li) '.';
            counter-increment: li;
            color: #246CB7;
            display: inline-block;
            text-align: center;
            margin-right: .5rem;

          }

          ::v-deep li {
            ol {
              > li::before {
                content: '2.1.';
                color: #246CB7;
                display: inline-block;
                text-align: center;
              }
            }
            ul {
              font-weight: 400;
            }
          }
        }

    main {
      padding: 0 1.625rem 2.5rem 0;

      .warning {
        display: flex;
        background-color: #FFF5E6;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2rem;
        border-radius: 3px;

        > p {
          font-size: .75rem;
          line-height: 1.125rem;
          color: #1F3245;
        }

        svg {
          margin: 0 .875rem;
        }

        .close {
          right: 12rem;
          top: 1rem;
          width: .625rem;
          height: .625rem;
          opacity: 0.3;
          margin-right: 1.25rem;
          margin-left: auto;
          cursor: pointer;
        }

        .close:hover {
          opacity: 1;
        }

        .close:before, .close:after {
          position: absolute;
          content: ' ';
          height: .625rem;
          width: 2px;
          background-color: #9D9D9D;
        }

        .close:before {
          transform: rotate(45deg);
        }

        .close:after {
          transform: rotate(-45deg);
        }
      }

      .additional {

        ul {
          font-weight: 400;
          margin-top: 1rem;

          .star::before {
            content: '*';
            font-size: 0.875rem;
            color: red;
            margin-right: .3rem;
          }
        }
      }
    }
  }

  ::v-deep .requirements {
    .info-block {
      display: flex;
      justify-content: space-between;

      > div {
        padding: 1.875rem;
        width: 26.5625rem;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 2rem 0;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
        border-radius: 8px;
        .icon1, .icon2{
          width: 2.813rem;
          height: 2.813rem;
        }
        .icon1{
          background: url('../../assets/icon/candidate1.svg') center center no-repeat;
          background-size: contain;
        }
        .icon2{
          background: url('../../assets/icon/candidate2.svg') center center no-repeat;
          background-size: contain;
        }

        > p {
          font-weight: 600;
        }

        ul {
          border-top: 1px solid #D5D6D9;
        }

      }
    }

  }

  .two-rows-navigation{
    a ::v-deep  span {
      color: #246CB7 !important;
    }
  }
  @media screen and (max-width: 768px){
    .two-rows-wrapper main {
      padding: 0 0 2.5rem 0;
      .requirements .info-block > div{
        width: 20.563rem;
      }
    }
  }
  @media screen and (max-width: 420px){
    .two-rows-wrapper main {
      .requirements .info-block {
        flex-direction: column;
        > div{
          width: 100%;
          margin: 1rem 0;
        }
      }
    }
  }
</style>
