<template>
  <div>
    <p class="name_section">
      <slot></slot>
    </p>
    <router-link class="info-banner" v-for="(content, i) of getAll" :key="i+'block'"
                 :to="`/formation_councils/${content.id}`">
      <p class="info-banner__date">{{ dateArr(content.date) }}</p>
      <p class="info-banner__name" v-trim="1">{{ content.title }}</p>
      <p class="info-banner__text" v-trim="2">{{ content.description }}</p>
    </router-link>
  </div>
</template>
<script>

export default {
  name: 'FormationPublicCouncils',
  data () {
    return {
      currentPage: 0,
      countPage: 50
    }
  },
  computed: {
    getAll () {
      return this.$store.getters.getFormationPublicCouncilsAll
    }
  },
  methods: {
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }).slice(0, -3))
    }
  },
  mounted () {
    document.title = 'Общественные советы при ФОИВ'
    this.$store.dispatch('setFormationPublicCouncilsAll')
  }
}
</script>
<style lang="scss" scoped>
  .name_section {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    margin-bottom: 1.38rem;
  }

  .info-banner {
    margin-bottom: 1.88rem;
    padding: 1.25rem 3.31rem 1.25rem 1.875rem;
    width: 100%;

    box-shadow: 0px .94rem 1.56rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    border: .06rem solid #E5E8EE;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.875rem;

    &__type {
      width: 2.8125rem;
      height: 2.8125rem;
      margin-bottom: auto;
      // margin-bottom: 1.25rem;
    }

    &__date {
      font-size: .875rem;
      line-height: 1.37rem;
      color: #5E686C;
      opacity: 0.8;
      margin-bottom: .625rem;
    }

    &__name {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.38rem;
      color: #1F3245;
      margin-bottom: .312rem;
    }

    &__text {
      font-size: .875rem;
      line-height: 1.38rem;
      color: #171717;
      opacity: 0.8;
    }

  }

  .news__pagination {
    padding-top: .625rem
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .info-banner * {
      width: 100%;
    }
  }

</style>
