<template>
    <article class="document-content">
      <header>
        <img src="/assets/icons/document.svg" alt="icon document">
        <a v-if="doc.link && doc.link !== 'null'" target="_blank" :href="`${doc.link}`"><h1>{{ doc.name }}</h1></a>
        <h1 v-else>{{ doc.name }}</h1>
      </header>
      <div class="document-content__linc">
        <img src="/assets/icons/download.svg" alt="icon download">
        <a target="_blank" :href="`${doc.url}`">Скачать {{ doc.size = '' }}</a>
      </div>
    </article>
</template>

<script>
export default {
  name: 'ChamberNormativeBaseDocuments',
  props: {
    doc: {
      type: Object,
      required: true,
      default: function () {
        return {
          name: '',
          url: '',
          type: ''
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .document-content {
    font-style: normal;
    font-weight: normal;
    font-size: .875rem;
    line-height: 1.57;
    color: rgba(23, 23, 23, 0.8);
    width: 80%;
    /*margin-bottom: 1rem;*/

    & h1 {
      font-weight: 600;
      color: #1F3245;
      margin-bottom: 1.125rem;
    }

    header {
      display: flex;
      align-items: flex-start;

      img {
        width: 2.8125rem;
        height: auto;
        margin-right: 1.25rem;
      }
    }

    &__linc {
      display: flex;
      align-items: flex-start;

      img {
        width: 1rem;
        height: auto;
        margin-left: calc(2.8125rem + 1.25rem);
        margin-right: .4375rem;
      }

      a {
        color: #246CB7;
      }
    }

  }
  @media screen and (max-width: 768px){
    .documents-block {
      width: 100% !important;
    }
    .document-content {
      width: 94.5%;
    }
  }
  @media screen and (max-width: 420px){
    .document-content {
      width: 100%;
      header{
        img{
          width: 2.8125rem;
          height: auto;
          margin-right: 1rem;
        }
      }
      h1{
        margin-bottom: .75rem;
      }
    }
  }

</style>
