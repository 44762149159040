<template>
    <div class="bread_crumb-block">
        <div v-for="(item, i) of navigationArray" :key="i" class="no_display_link">
            <div v-if="!!item.path">
                <router-link :to="item.path" v-if="!!item.path">{{item.title}}</router-link>
                <span> / </span>
            </div>
            <div v-else>
                <p>{{item.title}}</p>
            </div>
        </div>
        <div class="show_bread_crumb">
          <router-link :to="navigationArray[navigationArray.length-2].path">Вернуться назад</router-link>
        </div>
    </div>
</template>
<script>
export default {
  name: 'BreadCrumb',
  props: {
    navigationArray: {
      define: true
    }
  }
}
</script>
<style lang="scss" scoped>
.bread_crumb-block{
    width: 85.37rem;
    margin: .875rem auto;
    font-size: .75rem;
    line-height: 1.38rem;
    display: flex;
    justify-content: flex-start;
}
a{
    color: #246CB7;
}
p{
    color: #333333;
}
span{
    margin: 0 .44rem;
}
.show_bread_crumb{
  display: none;
  position: relative;
  padding-left: 1.125rem;
}
.show_bread_crumb:before{
  content: '';
  position: absolute;
  top: 23%;
  left: 0;
  width: .8rem;
  height: .8rem;
  background: url('../assets/icons/arrow.svg') center center no-repeat;
  transform: rotate(90deg);
}

@media screen and (max-width: 768px){
  .bread_crumb-block{
    width: 91.65%;
    margin: 1.36rem auto;
  }
  .no_display_link{
    display: none;
  }
  .show_bread_crumb{
    display: block;
  }
}
@media screen and (max-width: 420px){
  .bread_crumb-block{
    width: 92.4%;
    margin: 1.05rem auto .67rem auto;
  }
}
</style>
