<template>
<div>
  <h3>Документы</h3>
  <ul class="documents__type-list">
    <li v-for="(type, key) of documentsType" class="documents__type-item"
        :class="{'documents__active' : isActiveItem === key}"
        :key="`type${key}`"
        @click="ActiveItem(type, key)">
      {{ type }}
    </li>
  </ul>
  <h2 class="title-section" >{{ this.titleActive }}</h2>
  <documents-list v-for="item in document"
                  :key="`publicDocument${item.id}`"
                  class="documents__list"
                  :doc="item"
  />
</div>
</template>

<script>
import DocumentsList from '@/components/ChamberNormativeBaseDocuments'
import uniq from '@/methods/uniq'

export default {
  name: 'communityCouncilsDocuments',
  data () {
    return {
      document: [],
      documentsType: [],
      documentsAll: [],
      isActiveItem: -1,
      titleActive: 'Все'
    }
  },
  methods: {
    ActiveItem (name, i) {
      this.isActiveItem = i
      this.titleActive = name
      this.document = []
      this.documentsAll.map(item => {
        if (item.tagList.includes(name)) {
          this.document.push(item)
        }
      })
    },
    async setPlanDocuments () {
      const formdata = new FormData()
      formdata.append('systemTagList', JSON.stringify(['/community_councils_file']))
      let res = await fetch(`${this.$store.getters.getUrlApi}api/document/list`, {
        method: 'POST',
        body: formdata
      })
      res = await res.json()
      this.documentsAll = res.data.documents
      this.document = res.data.documents
      const arrayTags = []
      this.document.map(item => {
        item.tagList.map(val => {
          arrayTags.push(val)
        })
      })
      this.documentsType = uniq(arrayTags)
    }
  },
  mounted () {
    document.title = 'Документы'
    this.setPlanDocuments()
  },
  watch: {
    documentsType (value) {
      if (value.length) {
        this.ActiveItem(this.documentsType[0], 0)
      }
    }
  },
  components: {
    DocumentsList
  }
}
</script>

<style scoped lang="scss">
h3 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.375rem;
  color: #1F3245;
}
.documents__list{
  margin-bottom: 2rem;
}
.title-section {
  font-weight: 600;
  font-size: 1rem;
  color: #1F3245;
  margin-bottom: 2rem;
}
.documents {
  &__type-list {
    margin-left: 1.375rem;
    margin-bottom: 3.125rem;
  }
  &__type-item {
    list-style: disc;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #246CB7;

    cursor: pointer;
  }
  &__filter {
    margin-bottom: 1.875rem;
    display: inline-flex;
  }

  &__filter-select {
    width: 7.5rem;
  }

  &__filter-link {
    padding-left: 1.375em;
    display: flex;
    align-items: center;

    font-size: .75rem;
    line-height: 1.125rem;
    color: #003E78;
    opacity: 0.6;

    &::after {
      content: '';
      margin-left: 0.75rem;
      width: 0.7rem;
      height: 0.7rem;
      background-image: url('../../assets/icons/icon_link.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__list {
    margin-bottom: 2.5rem;
  }
  &__active {
    font-weight: 600;
  }
}
</style>
