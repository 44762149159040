<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="white-container">
      <div class="white-container__title">
        <h1>Общественные советы при ФОИВ</h1>
      </div>
      <div class="tabs">
        <p v-for="(tab, index) in tabs.list" :class="{'active' : isActiveTab === index}" :key="`tab${index}`"
           :id="`tab${index}`" @click="setTab(index)">{{ tab }}</p>
      </div>
      <component :is="tabs.pages[isActiveTab]" :data="content[isActiveTab]">{{ tabs.list[isActiveTab ]}}</component>

    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import CandidateConvocation from '@/components/activitiesSurveys/CommunityCandidateConvocation'
// import Documents from '@/components/activitiesSurveys/CommunityDocuments'
import communityCouncilsDocuments from '@/components/activity/communityCouncilsDocuments'
import FormationPublicCouncils from '@/components/monitoringCommissions/FormationPublicCouncils'

export default {
  name: 'CommunityCouncils',
  components: {
    BreadCrumb,
    CandidateConvocation,
    communityCouncilsDocuments,
    FormationPublicCouncils
  },
  data () {
    return {
      isActiveTab: Number(this.$route.query.tab) || 0,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '#'
        },
        {
          title: 'Общественные советы',
          path: '/public_councils'
        },
        {
          title: 'Кандидату в общественный совет'
        }
      ],
      tabs: {
        list: [
          'Формирование общественных советов',
          'Кандидату в общественный совет',
          'Документы'
        ],
        pages: [
          'formation-public-councils',
          'candidate-convocation',
          'communityCouncilsDocuments'
        ]
      },
      content: [
        [
          {
            id: 1,
            date: '08.19.2020',
            title: 'Завершен прием заявок',
            subtitle: 'Завершен прием заявок на конкурс в ОС при Росалкогольрегулировании, Росрыболовстве, Ростехнадзоре, ФАС России, Федеральной пробирной палате, а также замены членов ОС при Росавиации, Росгидромете, Росжелдоре, Минвостокразвития России, Минтруде России',
            link: '/termination-office'
          },
          {
            id: 1,
            date: '08.19.2020',
            title: 'Завершен прием заявок',
            subtitle: 'Завершен прием заявок на конкурс в ОС при Росалкогольрегулировании, Росрыболовстве, Ростехнадзоре, ФАС России, Федеральной пробирной палате, а также замены членов ОС при Росавиации, Росгидромете, Росжелдоре, Минвостокразвития России, Минтруде России',
            link: '/termination-office'
          },
          {
            id: 1,
            date: '08.19.2020',
            title: 'Завершен прием заявок',
            subtitle: 'Завершен прием заявок на конкурс в ОС при Росалкогольрегулировании, Росрыболовстве, Ростехнадзоре, ФАС России, Федеральной пробирной палате, а также замены членов ОС при Росавиации, Росгидромете, Росжелдоре, Минвостокразвития России, Минтруде России',
            link: '/termination-office'
          },
          {
            id: 1,
            date: '08.19.2020',
            title: 'Завершен прием заявок',
            subtitle: 'Завершен прием заявок на конкурс в ОС при Росалкогольрегулировании, Росрыболовстве, Ростехнадзоре, ФАС России, Федеральной пробирной палате, а также замены членов ОС при Росавиации, Росгидромете, Росжелдоре, Минвостокразвития России, Минтруде России',
            link: '/termination-office'
          },
          {
            id: 1,
            date: '08.19.2020',
            title: 'Завершен прием заявок',
            subtitle: 'Завершен прием заявок на конкурс в ОС при Росалкогольрегулировании, Росрыболовстве, Ростехнадзоре, ФАС России, Федеральной пробирной палате, а также замены членов ОС при Росавиации, Росгидромете, Росжелдоре, Минвостокразвития России, Минтруде России',
            link: '/termination-office'
          }
        ],
        [],
        'NORM_DOC_ORDER'

      ]
    }
  },
  methods: {
    setTab (index) {
      this.isActiveTab = index
    }
  },
  mounted () {
    document.title = 'Общественные советы при ФОИВ'
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .documents-block {
    width: 57rem;
  }
  .white-container {
    // width: auto;
    // margin: 0 5rem;
    padding: 2.5rem 2.94rem;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.81rem;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
  }

  h2 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1;
    color: #1F3245;
  }

  .tabs {
    display: flex;
    border-radius: .125rem;
    border-bottom: .06rem solid #D5D6D9;
    margin: 2rem 0;

    p {
      font-size: .75rem;
      line-height: 1.125rem;
      color: #003E78;
      opacity: 0.6;
      margin-right: 1.25rem;
      padding-bottom: 1.375rem;
      cursor: pointer;
      transition: font-size .2s;
    }

    .active {
      font-weight: 600;
      font-size: .813rem;
      line-height: 1.25rem;
      color: #246CB7;
      border-bottom: .125rem solid #246CB7;
      padding-bottom: 1.25rem;
      margin-bottom: -0.07rem;
      margin-top: -0.15rem;
      transition: font-size .2s;
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .white-container {
      width: auto;
      margin: 0 .75rem;
      padding: 1.86rem 1.25rem;
    }
    .contain .containet_block {
      width: 45%;
    }
    .tabs {
      overflow-x: auto;
      flex-wrap: nowrap;
      margin: 1.38rem -0.76rem 1.38rem 0;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      p {
        flex: 0 0 auto;
        padding-bottom: 1.05rem;
        margin-right: 1rem;
      }

      > .active {
        margin-bottom: 0;
        padding-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .white-container {
      width: 100%;
      margin: 0;
      padding: 1.88rem 1rem;
    }
    .contain .containet_block {
      width: 100%;
    }
  }
</style>
